<template>
  <div class="bg-hero banner-home">
    <v-sheet color="rgba(0, 0, 0, 0.3)">
      <v-container>
        <v-sheet
          color="transparent"
          class="d-flex align-start flex-column justify-center fill-height py-md-8"
          min-height="400px"
          max-width="500px"
        >
          <h1 class="text-white text-h6 text-md-h3">
            <span class="text-h5 text-md-h3 font-weight-bold">
              BUMI BUTUH
            </span>
            SENTUHAN KITA
          </h1>
          <p class="text-white text-body-2 text-md-body-1 my-2 my-md-4">
            Jadilah bagian dari aksi sosial positif yang langsung berdampak pada
            masyarakat secara modern, transparan, dan bertumbuh.
          </p>
          <v-btn
            to="/auth?type=signup"
            elevation="0"
            class="my-4 text-capitalize font-weight-bold rounded-xl"
            size="large"
            color="primary"
          >
            Bergabung Sekarang
          </v-btn>
        </v-sheet>
      </v-container>
    </v-sheet>
  </div>
</template>

<style>
.banner-home {
  background-image: url("/assets/images/banner-header.jpeg");
  background-position: center;
}
</style>
