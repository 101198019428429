<template>
  <HomePage />
</template>

<script lang="ts" setup>
import HomePage from "@/features/home/views/HomePage.vue";

definePageMeta({
  layout: "default",
});

useHead({
  title: "Gotong Royong",
});
</script>
