<template>
  <v-container fluid class="pa-0" id="main-container">
    <section class="pb-8" id="home">
      <HomeBanner />
    </section>
    <v-lazy
      v-model="isAboutVisible"
      :options="{ threshold: 0.3 }"
      min-height="200"
    >
      <section class="py-8" id="tentang-kami">
        <AboutUs />
      </section>
    </v-lazy>

    <v-lazy
      v-model="isAdvantagesVisible"
      :options="{ threshold: 0.3 }"
      min-height="200"
    >
      <section class="py-8" id="kelebihan-kami">
        <v-sheet color="green-lighten-5" rounded="0">
          <OurAdvantages />
        </v-sheet>
      </section>
    </v-lazy>
    <v-lazy
      v-model="isWorkVisible"
      :options="{ threshold: 0.3 }"
      min-height="200"
    >
      <section class="py-8" id="karya-kami">
        <OurWork />
      </section>
    </v-lazy>
    <v-lazy
      v-model="isDocumentationVisible"
      :options="{ threshold: 0.3 }"
      min-height="200"
    >
      <section class="py-8" id="dokumentasi-kegiatan">
        <ActivityDocumentation />
      </section>
    </v-lazy>
    <v-lazy>
      <section>
        <FooterBanner />
      </section>
    </v-lazy>
  </v-container>
</template>

<script lang="ts" setup>
import HomeBanner from "./components/HomeBanner.vue";

const AboutUs = defineAsyncComponent(() => import("./components/AboutUs.vue"));
const OurAdvantages = defineAsyncComponent(
  () => import("./components/OurAdvantages.vue"),
);
const OurWork = defineAsyncComponent(() => import("./components/OurWork.vue"));
const ActivityDocumentation = defineAsyncComponent(
  () => import("./components/ActivityDocumentation.vue"),
);
const FooterBanner = defineAsyncComponent(
  () => import("./components/FooterBanner.vue"),
);

const isAboutVisible = ref(false);
const isAdvantagesVisible = ref(false);
const isWorkVisible = ref(false);
const isDocumentationVisible = ref(false);
</script>

<style scoped>
/* Use Vuetify's built-in styles when possible, but add custom styles when needed */
#main-container :deep(img) {
  max-width: 100%;
  height: auto;
}

/* For larger viewports */
@media (min-width: 960px) {
  section {
    scroll-margin-top: 80px; /* Helps with smooth scrolling to sections */
  }
}
</style>
